<script setup lang="ts"></script>

<template>
  <slot />
</template>

<style>
@import url('@/assets/css/web_common.css');
@import url('@/assets/css/web_reset.css');
</style>
